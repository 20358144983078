import {FeatureStoreNames} from '@shared-types';
import {createActionGroup, props} from '@ngrx/store';
import {DefinedFiltersTypes} from '../types';

export const globalSearchActions = createActionGroup({
	source: FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY,
	events: {
		addLastSearch: props<{
			userId: string;
			search: string;
		}>(),
		removeSearch: props<{
			userId: string;
			search: string;
		}>(),
		changeDefinedFilters: props<{
			userId: string;
			filters: DefinedFiltersTypes[];
		}>(),
	},
});
