import {DefinedFiltersTypes, DefinedGlobalSearchFilter, GlobalSearchListType} from '../types';

export const allDefinedGlobalSearchFilters: DefinedGlobalSearchFilter[] = [
	{type: 'Today', label: 'struct.dcfinder.filter.tody', selected: false},
	{type: 'Last Week', label: 'struct.dcfinder.filter.last_week', selected: false},
	{type: 'Last Quarter', label: 'struct.dcfinder.filter.last_quarter', selected: false},
	{type: 'Last Month', label: 'struct.dcfinder.filter.month_back', selected: false},
	{type: 'Last Year', label: 'struct.dcfinder.filter.last_year', selected: false},
];

export const allDefinedFiltersTypes: DefinedFiltersTypes[] = ['Today', 'Last Week', 'Last Month', 'Last Quarter', 'Last Year'];

// export const defaultDefinedFiltersTypes: DefinedFiltersTypes[] = ['Last Week', 'Today', 'Last Month'];

export const allGlobalSearchCycleTypes: GlobalSearchListType[] = ['InProgress', 'Waiting', 'Finished', 'Signed', 'Rejected', 'Canceled', 'Archive'];
