import {DefinedFiltersTypes} from '../types';

export namespace GlobalSearchStore {
	/* ------------ Global Search State Structure ------------ */
	export type ILastSearches = {
		lastSearches: string[];
	};

	export type IGlobalSearchFilters = {
		definedFilters: DefinedFiltersTypes[];
	};

	export type IGlobalSearchState = {
		[userId: string]: ILastSearches & IGlobalSearchFilters;
	};

	/* ------------ Global Search Initial State ------------ */
	export const globalSearchInitialState: IGlobalSearchState = {};
}
