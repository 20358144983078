import {FeatureStoreNames} from '@shared-types';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {GlobalSearchStore} from './global-search.models';
import IGlobalSearchState = GlobalSearchStore.IGlobalSearchState;
import { allDefinedFiltersTypes } from '../consts';

export const selectGlobalSearchState = createFeatureSelector<IGlobalSearchState>(FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY);

export const selectAllLastSearchesByUserId = (userId: string) =>
	createSelector(selectGlobalSearchState, (state: IGlobalSearchState) => {
		return state[userId]?.lastSearches || [];
	});

export const selectLastSearches = (userId: string, numberOfSearches: number) =>
	createSelector(selectAllLastSearchesByUserId(userId), (allLastSearches: string[]) => {
		if (allLastSearches.length < numberOfSearches) {
			return allLastSearches;
		}
		return allLastSearches.slice(0, numberOfSearches);
	});

export const selectDefinedFilters = (userId: string) =>
	createSelector(selectGlobalSearchState, (state: IGlobalSearchState) => {
		return state[userId]?.definedFilters || allDefinedFiltersTypes;
	});
