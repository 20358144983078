import {CycleListType} from '@core/api/data-access';
import {ICycleQuery} from '@global-data';

export type SingableCyclesType = Omit<typeof CycleListType, 'Future' | 'Draft' | 'PrivateTemplate' | 'PublicTemplate' | 'PrivateForm' | 'PublicForm' | 'SentForm'>;

export const GlobalSearchListType: SingableCyclesType = {
	InProgress: 'InProgress',
	Waiting: 'Waiting',

	Finished: 'Finished',
	Completing: 'Completing',
	Signed: 'Signed',

	SignedApproved: 'SignedApproved',
	SignedAlert: 'SignedAlert',
	SignedRejected: 'SignedRejected',
	SignedTimeout: 'SignedTimeout',

	Rejected: 'Rejected',
	Canceled: 'Canceled',
	Archive: 'Archive',
} as const;

export type GlobalSearchListType = (typeof GlobalSearchListType)[keyof typeof GlobalSearchListType];

export const GlobalSearchDateMode = {
	creationDate: 'creationDate',
	lastUpdate: 'lastUpdate',
} as const;

export type GlobalSearchDateMode = (typeof GlobalSearchDateMode)[keyof typeof GlobalSearchDateMode];

export const DefinedFiltersTypes = {
	today: 'Today',
	lastWeek: 'Last Week',
	lastMonth: 'Last Month',
	lastQuarter: 'Last Quarter',
	lastYear: 'Last Year',
} as const;

export type DefinedFiltersTypes = (typeof DefinedFiltersTypes)[keyof typeof DefinedFiltersTypes];

export type DefinedGlobalSearchFilter = {
	type: DefinedFiltersTypes;
	label: string;
	selected: boolean;
};

export type GlobalSearchQuery = Omit<ICycleQuery, 'page' | 'size'>;
