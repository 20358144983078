import {createFeature, createReducer, on} from '@ngrx/store';
import {globalSearchActions} from './global-search.actions';
import {GlobalSearchStore} from './global-search.models';
import globalSearchInitialState = GlobalSearchStore.globalSearchInitialState;
import IGlobalSearchState = GlobalSearchStore.IGlobalSearchState;
import {FeatureStoreNames} from '@shared-types';

export const globalSearchFeature = createFeature({
	name: FeatureStoreNames.GLOBAL_SEARCH_FEATURE_KEY,
	reducer: createReducer(
		{...globalSearchInitialState},
		on(globalSearchActions.addLastSearch, (state, {userId, search}) => {
			const lastSearches: string[] = [...(state[userId]?.lastSearches || [])];

			if (lastSearches.indexOf(search) === -1) {
				lastSearches.unshift(search);
			}
			return {
				...state,
				[userId]: {
					...state[userId],
					lastSearches,
				},
			};
		}),
		on(globalSearchActions.removeSearch, (state, {userId, search}) => {
			const lastSearches: string[] = [...(state[userId]?.lastSearches || [])];
			const index: number = lastSearches.indexOf(search);
			if (index !== -1) {
				lastSearches.splice(index, 1);
			}
			return {
				...state,
				[userId]: {
					...state[userId],
					lastSearches,
				},
			};
		}),
		on(globalSearchActions.changeDefinedFilters, (state, {userId, filters}) => {
			return {
				...state,
				[userId]: {
					...state[userId],
					definedFilters: filters,
				},
			};
		}),
	),
});

export function globalSearchReducer(state: IGlobalSearchState | undefined, action: any): IGlobalSearchState {
	return globalSearchFeature.reducer(state, action);
}
